import { Row, Col, Tabs, Switch, Modal, Spin, Input, Button } from "antd";
import { Link } from "react-router-dom";
import cx from "classnames";
import moment from "moment-timezone";
import ProductCard from "./ProductCard";
import ConfirmDriver from "./ConfirmDriver";
import Commentaries from "./Commentaries";
import TableOrders from "../../../components/TableOrders";
import OrderDetail from "../../../components/OrderDetail";
import { ReactComponent as ClockRefresh } from "../../../assets/icons/clock-refresh.svg";
import { ReactComponent as Empty } from "../../../assets/icons/empty.svg";
import styles from "../styles/MonitorUI.module.css";
import Schedule from "../../branches/components/Schedule";

const { TabPane } = Tabs;

const MonitorUI = (props) => {
  const {
    modalOrder,
    closeDetails,
    openDetails,
    loaded,
    orderDetail,
    isAdmin,
    statistics,
    newOrders,
    dayOrders,
    branches,
    isDriver,
    acceptOrder,
    updateOrder,
    assignOrder,
    finishOrder,
    cancelOrder,
    getDriversBranch,
    handleDelivery,
    modalDriverConfirm,
    closeDriverConfirm,
    openDriverConfirm,
    closeModalCommetaries,
    openModalCommetaries,
    modalCommentaries,
    transferOrder,
    loyalty,
    newValueLoyalty,
    newActiveLoyalty,
    changeLoyaltyInfo,
    sending,
    updateNotes,
    tipAvailable,
    handleTipAvailable,
    sendingTip,
    scheduleVisible,
    setScheduleVisible,
    times,
    setTimes,
    updateSchedule
  } = props;

  const Card = ({ label, value, value2 }) => (
    <div className={styles.card}>
      <span className={styles.labelCard}>{label}</span>
      <div className={styles.valueCard}>
        <span className={cx(styles.value1, { [styles.numberOrders]: !value2 })}>
          {value}
        </span>
        {value2 && (
          <span className={styles.value2}>
            {value2} <span className={styles.wtx}>(sin IVA)</span>
          </span>
        )}
      </div>
    </div>
  );

  if (!loaded) {
    return (
      <div className={cx(styles.view, styles.loader)}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div className={styles.view}>
      {!isDriver ? (
        <>
          <Row gutter={40}>
            <Col xs={0} lg={16}>
              <div className={styles.newOrdersTable}>
                <div className={styles.header}>
                  <span>ORDENES NUEVAS</span>
                </div>
                {newOrders.length > 0 ? (
                  <div className={styles.body}>
                    <div className={cx(styles.row, styles.tableHeader)}>
                      <span className={styles.cellTicket}>Ticket</span>
                      <span className={styles.cellCostumer}>Cliente</span>
                      <span className={styles.cellTime}>Hora</span>
                      <span className={styles.cellAmount}>Monto</span>
                    </div>
                    <div className={styles.newOrders}>
                      {newOrders.map((order) => (
                        <div
                          key={order.id}
                          className={cx(styles.row, styles.tableItem)}
                          onClick={() => openDetails(order)}
                        >
                          <span className={styles.cellTicket}>
                            {order.cod_order}
                          </span>
                          <span className={styles.cellCostumer}>
                            {`${order.Customer.name} ${order.Customer.lastname}`}
                          </span>
                          <span className={styles.cellTime}>
                            {moment(order.date_created).format("hh:mma")}
                          </span>
                          <span
                            className={cx(styles.cellAmount, styles.amount)}
                          >
                            {order.currency}
                            {parseFloat(order.total).toFixed(2)}
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>
                ) : (
                  <div className={styles.wrapEmptyNewOrders}>
                    <span>Sin ordenes nuevas</span>
                  </div>
                )}
              </div>
              <div className={styles.activeOrders}>
                <div className={styles.activeOrdersInfo}>
                  <span className={styles.labelInfo}>
                    ESTATUS DE PEDIDOS TIEMPO REAL
                  </span>
                  {isAdmin && (
                    <Link to="/historial" className={styles.toHistory}>
                      Ver historial de ordenes
                      <ClockRefresh className={styles.iconClock} />
                    </Link>
                  )}
                </div>
                {dayOrders.length > 0 ? (
                  <TableOrders
                    monitor
                    orders={dayOrders}
                    openDetails={openDetails}
                  />
                ) : (
                  <div className={styles.dayOrdersEmpty}>
                    <span>No hay ordenes este día</span>
                  </div>
                )}
              </div>
            </Col>
            <Col xs={0} lg={8}>
              { /*isAdmin && (
                <div className={styles.tipAvailable}>
                  <span className={styles.labelInfo}>PROPINA ACTIVA</span>
                  <div className={styles.loyaltyInfo}>
                    <Switch
                      disabled={sendingTip}
                      checked={tipAvailable}
                      onChange={handleTipAvailable}
                    />
                  </div>
                </div>
              ) */}

              {
                isAdmin && (
                  <Button
                    onClick={() => setScheduleVisible(true)}
                    type="primary"
                    size="large"
                    className={styles.hoursBtn}
                  >
                    HORARIO DELIVERY
                  </Button>
                )
              }
              <div className={styles.tabsSummaryWrap}>
                <Tabs defaultActiveKey="1">
                  <TabPane tab="ESTE DÍA" key="1">
                    <Card label="PEDIDOS" value={statistics?.day?.orders} />
                    <Card
                      label="VENTAS"
                      value={`$${parseFloat(statistics?.day?.total).toFixed(
                        2
                      )}`}
                      value2={`$${parseFloat(
                        statistics?.day?.total_wtx
                      ).toFixed(2)}`}
                    />
                  </TabPane>
                  <TabPane tab="ESTA SEMANA" key="2">
                    <Card label="PEDIDOS" value={statistics?.week?.orders} />
                    <Card
                      label="VENTAS"
                      value={`$${parseFloat(statistics?.week?.total).toFixed(
                        2
                      )}`}
                      value2={`$${parseFloat(
                        statistics?.week?.total_wtx
                      ).toFixed(2)}`}
                    />
                  </TabPane>
                  <TabPane tab="ESTE MES" key="3">
                    <Card label="PEDIDOS" value={statistics?.month?.orders} />
                    <Card
                      label="VENTAS"
                      value={`$${parseFloat(statistics?.month?.total).toFixed(
                        2
                      )}`}
                      value2={`$${parseFloat(
                        statistics?.month?.total_wtx
                      ).toFixed(2)}`}
                    />
                  </TabPane>
                </Tabs>
                {/* isAdmin && (
                  <Link to="/historial" className={styles.toHistory}>
                    Ver reporte histórico de ventas
                    <ClockRefresh className={styles.iconClock} />
                  </Link>
                ) */}
              </div>
              {/* isAdmin && (
                <div className={styles.loyalty}>
                  <span className={styles.labelInfo}>
                    PORCENTAJE DE DESCUENTO - NUEVOS USUARIOS
                  </span>
                  <div className={styles.loyaltyInfo}>
                    <Input
                      placeholder="0.00"
                      suffix="%"
                      className={styles.input}
                      size="large"
                      value={loyalty.value}
                      onChange={newValueLoyalty}
                    />
                    <div className={styles.switchWrap}>
                      <span>Activar</span>
                      <Switch
                        checked={loyalty.active}
                        onChange={newActiveLoyalty}
                      />
                    </div>
                    <Button
                      size="large"
                      type="primary"
                      loading={sending}
                      onClick={changeLoyaltyInfo}
                    >
                      Guardar
                    </Button>
                  </div>
                </div>
              ) */}
              {isAdmin && (
                <div className={styles.branches}>
                  <div className={styles.branchesHeader}>
                    <span>Bodega</span>
                    <span>Estatus</span>
                  </div>
                  <div className={styles.branchesBody}>
                    {branches.map((branch, index) => (
                      <div key={branch.id} className={styles.branch}>
                        <div>{branch.name}</div>
                        <div className={styles.actionBranch}>
                          <span>Delivery Activo</span>
                          <Switch
                            checked={branch.delivery}
                            onChange={() =>
                              handleDelivery(branch.id, index, branch.delivery)
                            }
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </Col>
          </Row>
          <Row>
            <Col xs={24} lg={0}>
              <div className={styles.tabsSummaryWrap}>
                <Tabs defaultActiveKey="1">
                  <TabPane tab={`NUEVAS (${newOrders.length})`} key="1">
                    {newOrders.length ? (
                      newOrders.map((order) => (
                        <ProductCard
                          key={order.id}
                          order={order}
                          openDetails={openDetails}
                        />
                      ))
                    ) : (
                      <div className={styles.empty}>
                        <Empty />
                        <span>SIN ORDENES NUEVAS</span>
                      </div>
                    )}
                  </TabPane>
                  <TabPane tab={`ORDENES (${dayOrders.length})`} key="2">
                    {dayOrders.length ? (
                      <TableOrders
                        monitor
                        orders={dayOrders}
                        openDetails={openDetails}
                      />
                    ) : (
                      <div className={styles.empty}>
                        <Empty />
                        <span>NO SE HAN ENCONTRADO ORDENES</span>
                      </div>
                    )}
                  </TabPane>
                  <TabPane tab="REPORTE" key="3">
                    <Card label="PEDIDOS" value={statistics?.day?.orders} />
                    <Card
                      label="VENTAS"
                      value={`$${parseFloat(statistics?.day?.total).toFixed(
                        2
                      )}`}
                      value2={`$${parseFloat(
                        statistics?.day?.total_wtx
                      ).toFixed(2)}`}
                    />
                  </TabPane>
                </Tabs>
              </div>
            </Col>
          </Row>
        </>
      ) : (
        <Row>
          <Col span={24}>
            <div className={styles.tabsSummaryWrap}>
              <Tabs defaultActiveKey="1">
                <TabPane tab="ORDENES ACTIVAS" key="1">
                  {newOrders.length ? (
                    newOrders.map((order) => (
                      <ProductCard
                        key={order.id}
                        order={order}
                        openDetails={openDetails}
                      />
                    ))
                  ) : (
                    <div className={styles.empty}>
                      <Empty />
                      <span>SIN ORDENES NUEVAS</span>
                    </div>
                  )}
                </TabPane>
                <TabPane tab="HISTORIAL" key="2">
                  {dayOrders.length ? (
                    <TableOrders
                      monitor
                      orders={dayOrders}
                      openDetails={openDetails}
                    />
                  ) : (
                    <div className={styles.empty}>
                      <Empty />
                      <span>NO SE HAN ENCONTRADO ORDENES</span>
                    </div>
                  )}
                </TabPane>
              </Tabs>
            </div>
          </Col>
        </Row>
      )}
      <Modal
        destroyOnClose
        wrapClassName={styles.modalOrder}
        footer={false}
        visible={modalDriverConfirm}
        width={400}
        closable={false}
        onCancel={closeDriverConfirm}
      >
        <ConfirmDriver close={closeDriverConfirm} updateOrder={updateOrder} />
      </Modal>
      <Modal
        destroyOnClose
        wrapClassName={styles.modalOrder}
        footer={false}
        visible={modalCommentaries}
        width={400}
        closable={false}
        onCancel={closeModalCommetaries}
      >
        <Commentaries
          close={closeModalCommetaries}
          finishOrder={finishOrder}
          cancelOrder={cancelOrder}
          type={modalCommentaries}
        />
      </Modal>
      <Modal
        destroyOnClose
        wrapClassName={styles.modalOrder}
        footer={false}
        visible={modalOrder}
        width={1150}
        closable={false}
        onCancel={closeDetails}
      >
        <OrderDetail
          order={orderDetail}
          monitor
          close={closeDetails}
          branches={branches}
          acceptOrder={acceptOrder}
          updateOrder={updateOrder}
          assignOrder={assignOrder}
          getDriversBranch={getDriversBranch}
          openDriverConfirm={openDriverConfirm}
          openModalCommetaries={openModalCommetaries}
          transferOrder={transferOrder}
          updateNotes={updateNotes}
        />
      </Modal>
      <Modal
        destroyOnClose
        closable={false}
        visible={scheduleVisible}
        footer={false}
        onCancel={() => setScheduleVisible(false)}
        width={400}
      >
        <Schedule
          close={() => setScheduleVisible(false)}
          times={times}
          setTimes={setTimes}
          updateSchedule={updateSchedule}
        />
      </Modal>
    </div>
  );
};

export default MonitorUI;
