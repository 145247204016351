import {saveAs} from 'file-saver';
import XLSX from 'xlsx';
import moment from 'moment';
import 'moment/locale/es';
import 'moment-timezone';

const s2ab = (s) => { 
  var buf = new ArrayBuffer(s.length);
  var view = new Uint8Array(buf);
  for (var i=0; i<s.length; i++) view[i] = s.charCodeAt(i) & 0xFF;
  return buf;    
};

const exportData = (orders) => {
  const wb = XLSX.utils.book_new();
  const title = 'Reporte de Ordenes';

  wb.Props = {
    Title: title,
    Subject: 'Reporte de Ordenes',
    Author: 'Paulaner SV',
    CreatedDate: new Date(),
  };
  wb.SheetNames.push('Ordenes');
  const ws_data = [
    [
      'Ticket',
      'Fecha',
      'Tienda',
      'Cliente',
      'DUI',
      'Correo',
      'Direccion',
      'Detalle Orden',
      'Tipo pago',
      'Subtotal',
      'Delivery Fee',
      'Propina',
      'Total',
      'Driver',
      'Estado',
    ]
  ];

  orders.forEach(order => {
    const newRegister = [
      order.cod_order,
      moment.tz(order.date_created, 'America/El_Salvador').format('DD/MM/YYYY, HH:mm a'),
      order.location.name,
      order.Customer.name + " " + order.Customer.lastname,
      order.Customer.identity,
      order.Customer.email,
      order.order_details.map(detaprod => detaprod.quantity + " - " + detaprod.title + ",").join(' '),
      order.order_payments[0].name,
      parseFloat((order.total - order.delivery_fee - order.driver_tip).toFixed(2)),
      order.delivery_fee,
      order.driver_tip,
      order.total,
      order.employee ? `${order.employee.name} ${order.employee.lastname}` : '-',
      order.status === 5 ? 'Finalizada' : 'Cancelada',
    ];
    ws_data.push(newRegister);
  });
  const ws = XLSX.utils.aoa_to_sheet(ws_data);
  wb.Sheets['Ordenes'] = ws;
  const wbout = XLSX.write(wb, {bookType:'xlsx',  type: 'binary'});
  saveAs(new Blob([s2ab(wbout)],{type:"application/octet-stream"}), `${title}.xlsx`);
};

export default exportData;
