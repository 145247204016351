import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Form,
  Input,
  Button,
  Checkbox,
  Row,
  Col,
  Switch,
  Select,
  Upload,
  Image,
  message,
  Modal,
} from "antd";
import cx from "classnames";
import produce from "immer";
import {
  updateProduct,
  createProduct,
  removeMonitorProduct,
} from "../../../redux/slices/products";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { normFile, beforeUpload, dummyRequest } from "../../../utils/images";
import { validateFloatKeyPress } from "../../../utils/format";
import { ReactComponent as Trash } from "../../../assets/icons/trash.svg";
import { ReactComponent as Add } from "../../../assets/icons/add-round.svg";
import styles from "../styles/Editor.module.css";

const { Option } = Select;

const Editor = ({
  product,
  type,
  categories,
  toppings,
  handleProduct,
  resetProduct,
  handleType,
  branch,
  animations,
}) => {
  const [deleting, handleDeleting] = useState(false);
  const [sending, handleSending] = useState(false);
  const [image, handleImage] = useState("");
  const [imageSmall, handleImageSmall] = useState("");
  const [imageBase, handleImageBase] = useState("");
  const [content, handleContent] = useState('');
  const [toppingsSelected, handleToppingsSelected] = useState([]);
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    handleImage("");
    handleImageSmall("");
    // handleImageBase("");
    handleToppingsSelected([]);
    form.resetFields();
    if (type === "edit") {
      const custom = JSON.parse(product.custom_data);
      const data = {
        name: product.name,
        price: product.price,
        category: product.categoryId,
        shortDescription: product.small_desc,
        longDescription: product.desc,
        homeDescription: product.home_desc,
        active: product.active,
        calories: "",
        proteins: "",
        fat: "",
        salt: "",
        calcium: "",
        fat_trans: "",
        sugar: "",
        allergens: "",
        toppingsFree: "",
        customType: product.custom_type,
        // delivery: product.delivery,
      };
      form.setFieldsValue(data);
      handleImage(product.image || "");
      handleImageSmall(product.small_image || "");
      //handleImageBase(product.base_image || "");
      const newToppings = [{id:1, quantity: 1}];
      handleToppingsSelected(newToppings);
    }
  }, [product]);

  const CheckMark = ({ label, value }) => (
    <Col span={12}>
      <Checkbox
        className={styles.check}
        value={value}
        style={{ lineHeight: "32px" }}
      >
        {label}
      </Checkbox>
    </Col>
  );

  const editTopping = (typeVal, val, index) => {
    const newSelected = produce(toppingsSelected, (draftState) => {
      draftState[index][typeVal] = val;
    });
    handleToppingsSelected(newSelected);
  };

  const removeTopping = (index) => {
    const newSelected = produce(toppingsSelected, (draftState) => {
      draftState.splice(index, 1);
    });
    handleToppingsSelected(newSelected);
  };

  const addTopping = () => {
    const newSelected = produce(toppingsSelected, (draftState) => {
      let idx = 0;
      toppings.map((topping) => {
        if (
          toppingsSelected.findIndex((el) => el.id === topping.id) < 0 &&
          idx === 0
        ) {
          idx = topping.id;
        }
      });
      draftState.push({ id: idx, quantity: 0 });
    });
    handleToppingsSelected(newSelected);
  };

  const save = async () => {
    try {
      handleSending(true);
      const values = await form.validateFields();
      if (imageSmall && imageSmall.length) {
        const info = new FormData();
        if (product) {
          info.append("id", product.id);
        }
        const topping_cats = toppingsSelected.map((ts) => ts.id);
        const req_topping_free_by_cats = {};
        toppingsSelected.map((ts) => {
          req_topping_free_by_cats[ts.id] = ts.quantity;
        });
        const custom = {
          allergens: values.allergens,
          nutricional_info: {
            cal: values.calories,
            pro: values.proteins,
            fat: values.fat,
            sal: values.salt,
            clc: values.calcium,
            ftrans: values.fat_trans,
            azu: values.sugar,
          },
          topping_cats,
          req_topping_free_by_cats,
          toppings_free: values.toppingsFree?.length ? values.toppingsFree : 0,
        };
        info.append("location", branch);
        info.append("name", values.name);
        info.append("price", values.price);
        info.append("category", values.category);
        info.append("active", values.active);
        // info.append('delivery', product.delivery);
        info.append("delivery", true);
        info.append("mini_description", values.shortDescription || "");
        info.append("description", values.longDescription);
        info.append("home_desc", values.homeDescription || "");
        info.append("custom_type", values.customType);
        info.append("custom", JSON.stringify(custom));
        info.append(
          "image",
          values.image?.length ? values.image[0]?.originFileObj : image
        );
        info.append(
          "small_image",
          values?.imageSmall?.length
            ? values.imageSmall[0]?.originFileObj
            : imageSmall
        );

        console.log(info);
        if (product) {
          const response = await dispatch(
            updateProduct(product.id, info, values.category, product.categoryId)
          );
          if (response.status === "success") {
            message.success("Cambios realizados con éxito");
            handleProduct(response.product);
          } else {
            message.error(response.status + ", ¡Hubo un problema! Inténtalo de nuevo");
          }
        } else {
          const response = await dispatch(createProduct(info, values.category));
          if (response.status === "success") {
            message.success("Cambios realizados con éxito");
            handleType("edit");
            handleProduct(response.product);
          } else {
            message.error(response.status + " - no se pudo procesar, Inténtalo de nuevo");
          }
        }
      } else {
        message.error("Incluye las imagenes requeridas");
      }
      handleSending(false);
    } catch (e) {
      console.log(e);
    }
  };

  /* const confirmDelete = () => {
    Modal.confirm({
      title: '¿Estás seguro de eliminar esta producto?',
      content: 'No se podrá recuperar esta información.',
      okText: 'Eliminar',
      cancelText: 'Cancelar',
      okType: 'danger',
      onOk() {
        deleteProduct();
      },
      onCancel() {},
    });
  }; */

  /* const deleteProduct = async () => {
    handleDeleting(true);
    const response = await dispatch(removeMonitorProduct(product.id, product.categoryId));
    if (response.status === 'success') {
      message.success('Se ha borrado esta producto');
      resetProduct();
    } else {
      message.error('¡Hubo un problema! Inténtalo de nuevo');
    }
    handleDeleting(false);
  }; */

  return (
    <div className={cx(styles.editor, "editor-product")}>
      <Form
        name="product-editor"
        autoComplete="off"
        colon={false}
        requiredMark={false}
        form={form}
        onFinish={save}
        initialValues={{ active: true, toppingsFree: "" }}
      >
        <div className={cx(styles.mainInfo, styles.section)}>
          <Row>
            <Col span={24}>
              <Row>
                <Col span={15}>
                  <div className={styles.header}>
                    <h1>{product ? product.name : "Crear Producto"}</h1>
                  </div>
                </Col>
                <Col span={9}>
                  <div className={styles.activeProduct}>
                    <Form.Item
                      name="active"
                      label={
                        <span className={styles.blue}>Producto activado</span>
                      }
                      valuePropName="checked"
                    >
                      <Switch />
                    </Form.Item>
                    {/* <Form.Item
                      name="delivery"
                      className={styles.delivery}
                      label={
                        <span className={styles.blue}>
                          Delivery
                        </span>
                      }
                      valuePropName="checked">
                      <Switch />
                    </Form.Item> */}
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={40}>
            <Col span={14}>
              <Form.Item
                className={styles.itemColumn}
                label={<span className={styles.label}>CATEGORÍA</span>}
                rules={[{ required: true, message: "Ingresar categoría" }]}
                name="category"
              >
                <Select size="large" className={styles.input}>
                  {categories.map((category) => {
                    if(!(category.type == 1 || category.type == 6)) {
                      return;
                    }
                    return (
                    <Option key={category.id} value={category.id}>
                      {category.name}
                    </Option>
                    )
                  })}
                </Select>
              </Form.Item>
              <Form.Item
                label={<span className={styles.label}>NOMBRE</span>}
                name="name"
                className={styles.itemColumn}
                rules={[{ required: true, message: "Ingresar nombre" }]}
              >
                <Input size="large" className={styles.input} />
              </Form.Item>
              <Form.Item
                label={<span className={styles.label}>PRECIO</span>}
                name="price"
                normalize={validateFloatKeyPress}
                className={styles.itemColumn}
                rules={[{ required: true, message: "Ingresar precio" }]}
              >
                <Input size="large" prefix="$" className={styles.input} />
              </Form.Item>
              <Form.Item
                label={<span className={styles.label}>DESCRIPCIÓN CORTA</span>}
                name="shortDescription"
                required
                className={styles.itemColumn}
              >
                <Input size="large" className={styles.input} />
              </Form.Item>
              <Form.Item
                label={
                  <span className={styles.label}>DESCRIPCIÓN PRINCIPAL</span>
                }
                name="longDescription"
                required
                className={styles.itemColumn}
                rules={[
                  {
                    required: true,
                    message: "Ingresar descripción en detalle",
                  },
                ]}
              >
                <Input.TextArea size="large" className={styles.input} />
              </Form.Item>
              <Form.Item
                label={
                  <span className={styles.label}>CARACTERISTICAS</span>
                }
                name="homeDescription"
                required
                className={styles.itemColumn}
              >
                <ReactQuill theme="snow" value={content} onChange={handleContent}/>
              </Form.Item>
              {/* 
              <Form.Item
                className={styles.itemColumn}
                label={<span className={styles.label}>ANIMACIÓN</span>}
                rules={[{ required: true, message: "Ingresar animación" }]}
                name="customType"
              >
                <Select size="large" className={styles.input}>
                  {animations.map((animation) => (
                    <Option key={animation.id} value={animation.id}>
                      {animation.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              */}
            </Col>
            <Col span={10}>
              <div className={styles.photoWrap}>
                <Row gutter={20}>
                  <Col span={10}>
                    {image.length ? (
                      <Image
                        width="100%"
                        src={image}
                        className={styles.photo}
                      />
                    ) : (
                      <div className={styles.photoEmpty} />
                    )}
                  </Col>
                  <Col span={14}>
                    <Form.Item
                      name="image"
                      valuePropName="fileList"
                      getValueFromEvent={normFile}
                      label={
                        <span className={styles.label}>IMAGEN PRINCIPAL</span>
                      }
                      className={styles.itemColumn}
                      extra="(Formatos jpg o png de 340x360px)"
                    >
                      <Upload
                        maxCount={1}
                        showUploadList={false}
                        customRequest={(e) => dummyRequest(e, handleImage)}
                        beforeUpload={beforeUpload}
                        listType="picture"
                      >
                        <Button className={styles.uploadImage}>
                          Cambiar imagen
                        </Button>
                      </Upload>
                    </Form.Item>
                  </Col>
                </Row>
              </div>
              <div className={styles.photoWrap}>
                <Row gutter={20}>
                  <Col span={10}>
                    {imageSmall.length ? (
                      <Image
                        width="100%"
                        src={imageSmall}
                        className={styles.photo}
                      />
                    ) : (
                      <div className={styles.photoEmpty} />
                    )}
                  </Col>
                  <Col span={14}>
                    <Form.Item
                      name="imageSmall"
                      valuePropName="fileList"
                      label={
                        <span className={styles.label}>IMAGEN DETALLE</span>
                      }
                      className={styles.itemColumn}
                      extra="(Formatos jpg o png de 600x630px)"
                      getValueFromEvent={normFile}
                    >
                      <Upload
                        maxCount={1}
                        showUploadList={false}
                        customRequest={(e) => dummyRequest(e, handleImageSmall)}
                        beforeUpload={beforeUpload}
                        listType="picture"
                      >
                        <Button className={styles.uploadImage}>
                          Cambiar imagen
                        </Button>
                      </Upload>
                    </Form.Item>
                  </Col>
                </Row>
              </div>
              {/*
              <div className={styles.photoWrap}>
                <Row gutter={20}>
                  <Col span={10}>
                    {imageBase.length ? (
                      <Image
                        width="100%"
                        src={imageBase}
                        className={styles.photo}
                      />
                    ) : (
                      <div className={styles.photoEmpty} />
                    )}
                  </Col>
                  <Col span={14}>
                    <Form.Item
                      name="imageBase"
                      valuePropName="fileList"
                      label={<span className={styles.label}>IMAGEN BASE</span>}
                      className={styles.itemColumn}
                      extra="(Formatos jpg o png de 500x400px)"
                      getValueFromEvent={normFile}
                    >
                      <Upload
                        maxCount={1}
                        showUploadList={false}
                        customRequest={(e) => dummyRequest(e, handleImageBase)}
                        beforeUpload={beforeUpload}
                        listType="picture"
                      >
                        <Button className={styles.uploadImage}>
                          Cambiar imagen
                        </Button>
                      </Upload>
                    </Form.Item>
                  </Col>
                </Row>
              </div>
              */}
            </Col>
          </Row>
        </div>
        {/* 
        <div className={cx(styles.second, styles.section)}>
          <Row>
            <Col span={12}>
              <div className={styles.firstSubsection}>
                <span className={cx(styles.label, styles.labelSubsection)}>
                  VALORES NUTRICIONALES
                </span>
                <div className={styles.nutricionalValues}>
                  <Form.Item
                    label={<span className={styles.label}>Calorías</span>}
                    name="calories"
                    rules={[
                      {
                        required: true,
                        message: "Ingresar cantidad de calorías",
                      },
                    ]}
                  >
                    <Input className={styles.input} />
                  </Form.Item>
                  <Form.Item
                    label={<span className={styles.label}>Grasas</span>}
                    name="fat"
                    rules={[
                      {
                        required: true,
                        message: "Ingresar cantidad de grasas",
                      },
                    ]}
                  >
                    <Input className={styles.input} />
                  </Form.Item>
                  <Form.Item
                    label={<span className={styles.label}>Proteinas</span>}
                    name="proteins"
                    rules={[
                      {
                        required: true,
                        message: "Ingresar cantidad de proteinas",
                      },
                    ]}
                  >
                    <Input className={styles.input} />
                  </Form.Item>
                  <Form.Item
                    label={<span className={styles.label}>Sal</span>}
                    name="salt"
                    rules={[
                      { required: true, message: "Ingresar cantidad de sal" },
                    ]}
                  >
                    <Input className={styles.input} />
                  </Form.Item>
                  <Form.Item
                    label={<span className={styles.label}>Calcio</span>}
                    name="calcium"
                    rules={[
                      {
                        required: true,
                        message: "Ingresar cantidad de calcio",
                      },
                    ]}
                  >
                    <Input className={styles.input} />
                  </Form.Item>
                  <Form.Item
                    label={<span className={styles.label}>Grasas Trans</span>}
                    name="fat_trans"
                    rules={[
                      {
                        required: true,
                        message: "Ingresar cantidad de grasas trans",
                      },
                    ]}
                  >
                    <Input className={styles.input} />
                  </Form.Item>
                  <Form.Item
                    label={<span className={styles.label}>Azúcar</span>}
                    name="sugar"
                    rules={[
                      {
                        required: true,
                        message: "Ingresar cantidad de azúcar",
                      },
                    ]}
                  >
                    <Input className={styles.input} />
                  </Form.Item>
                </div>
              </div>
            </Col>
            <Col span={12}>
              <div className={styles.secondSubsection}>
                <span className={cx(styles.label, styles.labelSubsection)}>
                  ALÉRGENOS
                </span>
                <div>
                  <Form.Item name="allergens">
                    <Checkbox.Group>
                      <Row>
                        <CheckMark label="Cereales/gluten" value="CEREALS" />
                        <CheckMark label="Apio/derivados" value="CELERY" />
                        <CheckMark label="Frutos cascara" value="NUTS" />
                        <CheckMark
                          label="Sulfijos/Dioxido..."
                          value="SULFIXES"
                        />
                        <CheckMark label="Leche/derivados" value="MILK" />
                        <CheckMark label="Sésamo" value="SESAME" />
                        <CheckMark label="Huevo/derivados" value="EGG" />
                        <CheckMark label="Cacahuetes" value="PEANUTS" />
                        <CheckMark label="Mostaza/derivados" value="MUSTARD" />
                        <CheckMark label="Soja" value="SOY" />
                      </Row>
                    </Checkbox.Group>
                  </Form.Item>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className={cx(styles.third, styles.section)}>
          <Row gutter={[20, 20]}>
            {toppingsSelected.map((ts, index) => (
              <Col key={ts.id} span={6}>
                <div className={cx(styles.itemColumn, styles.itemTopping)}>
                  <div className={styles.labelTopping}>
                    <span className={styles.label}>
                      {`TOPPING ${index + 1}`}
                    </span>
                    <div
                      onClick={() => removeTopping(index)}
                      className={styles.trash}
                    >
                      <Trash />
                    </div>
                  </div>
                  <Select
                    size="large"
                    className={styles.input}
                    value={ts.id}
                    onChange={(e) => editTopping("id", e, index)}
                  >
                    {toppings.map((topping) => (
                      <Option
                        disabled={
                          toppingsSelected.findIndex(
                            (el) => el.id === topping.id
                          ) >= 0
                        }
                        key={topping.id}
                        value={topping.id}
                      >
                        {topping.name}
                      </Option>
                    ))}
                  </Select>
                  <Input
                    type="number"
                    size="large"
                    className={cx(styles.input, styles.inputTopping)}
                    prefix="№ gratis:"
                    value={ts.quantity}
                    onChange={(e) =>
                      editTopping("quantity", e.target.value, index)
                    }
                  />
                </div>
              </Col>
            ))}
            {toppingsSelected.length < toppings.length && (
              <Col span={6}>
                <div className={styles.addToppingWrapper}>
                  <div onClick={addTopping} className={styles.addTopping}>
                    <Add />
                    <span>NUEVO TOPPING</span>
                  </div>
                </div>
              </Col>
            )}
            {!!toppingsSelected.length && (
              <Col span={24}>
                <Form.Item
                  label={<span className={styles.label}>TOPPINGS GRATIS</span>}
                  name="toppingsFree"
                  required
                  className={styles.itemColumn}
                >
                  <Input
                    type="number"
                    size="large"
                    className={cx(styles.input, styles.freeToppings)}
                  />
                </Form.Item>
              </Col>
            )}
          </Row>
        </div>
        */}
        <div className={cx(styles.fourth)}>
          <Row>
            <Col span={24}>
              <div className={styles.submitWrap}>
                <Form.Item className={styles.itemSubmit}>
                  {/* <Button
                    onClick={confirmDelete}
                    loading={deleting}
                    className={cx(styles.submit, styles.delete)}
                    type="danger"
                    size="large">
                    ELIMINAR
                  </Button> */}
                  <Button
                    onClick={resetProduct}
                    loading={deleting}
                    className={cx(styles.submit, styles.delete)}
                    size="large"
                  >
                    CANCELAR
                  </Button>
                  <Button
                    loading={sending}
                    className={styles.submit}
                    type="primary"
                    size="large"
                    htmlType="submit"
                  >
                    GUARDAR
                  </Button>
                </Form.Item>
              </div>
            </Col>
          </Row>
        </div>
      </Form>
    </div>
  );
};

export default Editor;
